var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-form",
        {
          ref: "createAlbumRequest",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.createAlbumRequest,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { border: "1px solid rgb(96 99 102 / 19%)" } },
            [
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { float: "right", "margin-right": "100px" },
                      attrs: { prop: "idClass", label: "Lớp / Trường" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "Album đăng cho trường hoặc lớp",
                          },
                          model: {
                            value: _vm.createAlbumRequest.idClass,
                            callback: function ($$v) {
                              _vm.$set(_vm.createAlbumRequest, "idClass", $$v)
                            },
                            expression: "createAlbumRequest.idClass",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: "0", label: "Trường" },
                          }),
                          _vm._l(_vm.classListCommon, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.className, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: { label: "Tên Album", prop: "albumName" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập tên album" },
                        model: {
                          value: _vm.createAlbumRequest.albumName,
                          callback: function ($$v) {
                            _vm.$set(_vm.createAlbumRequest, "albumName", $$v)
                          },
                          expression: "createAlbumRequest.albumName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { clear: "both" },
                      attrs: { label: "Mô tả", prop: "desc" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 2,
                          placeholder: "Nhập mô tả",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.createAlbumRequest.albumDescription,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.createAlbumRequest,
                              "albumDescription",
                              $$v
                            )
                          },
                          expression: "createAlbumRequest.albumDescription",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "40px",
                    color: "orange",
                    "margin-top": "-30px",
                  },
                },
                [_vm._v(_vm._s("Chọn tối đa " + _vm.albumMaxNumber + " ảnh"))]
              ),
              _vm.showProgress
                ? _c("el-progress", {
                    staticStyle: {
                      width: "25%",
                      float: "left",
                      "margin-top": "30px",
                    },
                    attrs: { percentage: _vm.percentage, color: _vm.colors },
                  })
                : _vm._e(),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticStyle: { "margin-left": "35px", clear: "both" },
                  attrs: {
                    action: "/api/upload/",
                    "list-type": "picture-card",
                    "auto-upload": false,
                    "file-list": _vm.fileList,
                    "on-change": _vm.handleChangePicture,
                    "on-remove": _vm.handleRemove,
                    "before-remove": _vm.beforeRemove,
                    multiple: "",
                    limit: _vm.albumMaxNumber,
                    "on-exceed": _vm.handleExceed,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "file",
                      fn: function ({ file }) {
                        return _c("div", {}, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                background: "orange",
                                padding: "5px",
                              },
                            },
                            [_vm._v("Mới")]
                          ),
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: { src: file.url, alt: "" },
                          }),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-preview",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePictureCardPreview(file)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                              !_vm.disabled
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRemove(file)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ])
                      },
                    },
                  ]),
                },
                [
                  _vm.imageUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.imageUrl },
                      })
                    : _vm._e(),
                  _c(
                    "i",
                    {
                      staticClass: "el-icon-plus",
                      staticStyle: { "font-style": "normal" },
                      attrs: { slot: "default" },
                      slot: "default",
                    },
                    [_vm._v("Thêm Ảnh")]
                  ),
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: { width: "30%", visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px", float: "right" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "button-over",
                  attrs: { type: "success", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      return _vm.submitUpload("createAlbumRequest")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _vm._v("\n        Lưu thay đổi\n      "),
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  staticStyle: { "text-decoration": "none" },
                  attrs: { to: "/student-quality/album" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "button-over",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.createEmployeeDialog()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _vm._v("\n          Hủy\n        "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }