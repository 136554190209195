import { render, staticRenderFns } from "./AddAlbum.vue?vue&type=template&id=733ac3a8&scoped=true"
import script from "./AddAlbum.vue?vue&type=script&lang=js"
export * from "./AddAlbum.vue?vue&type=script&lang=js"
import style0 from "./AddAlbum.vue?vue&type=style&index=0&id=733ac3a8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733ac3a8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/SpMFxzSoQ/0/hero/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('733ac3a8')) {
      api.createRecord('733ac3a8', component.options)
    } else {
      api.reload('733ac3a8', component.options)
    }
    module.hot.accept("./AddAlbum.vue?vue&type=template&id=733ac3a8&scoped=true", function () {
      api.rerender('733ac3a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/album/AddAlbum.vue"
export default component.exports